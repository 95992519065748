import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player';

import { useWidth } from '../../context/ScreenWidthContext';

import Protect from 'react-app-protect';
import 'react-app-protect/dist/index.css';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, S3 } from '@aws-sdk/client-s3';

// Images
import ChannelLogo from '../../assets/images/clayton-roku-app-icon.png';
import topBgFull from '../../assets/images/fob-top-bg-full.png';

// Objects
import Files from '../../FileUpload.json';

const todayDate = new Date().toISOString().slice(0, 10);
console.log(todayDate);

const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: top center, top center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const Uploader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 2rem auto;
  justify-content: center;
  .current-specs div {
    margin: 1rem 0;
    span {
      text-transform: uppercase;
    }
  }
  .form-fields {
    display: flex;
    flex-wrap: wrap;
  }
  .form-fields input {
    width: 97.5%;
    height: 3rem;
    background: #f6f6f6;
    border: 0;
    margin: 0.5rem 0 0 0;
    padding-left: 1rem;
    border-radius: 6px;
    font-weight: 400;
    color: #afafaf;
    -webkit-box-shadow: -2px -2px 21px 4px rgba(166, 166, 166, 0.04);
    box-shadow: -2px -2px 21px 4px rgba(166, 166, 166, 0.04);
  }
  .form-fields textarea {
    width: 97.5%;
    resize: vertical;
    height: 3rem;
    max-width: 100%;
    max-height: 100%;
    background: #f6f6f6;
    border: 0;
    margin: 0.5rem 0 0 0;
    border-radius: 6px;
    font-weight: 400;
    padding-top: 1rem;
    padding-left: 1rem;
    color: #afafaf;
    min-height: 8rem;
    -webkit-box-shadow: -2px -2px 21px 4px rgba(166, 166, 166, 0.04);
    box-shadow: -2px -2px 21px 4px rgba(166, 166, 166, 0.04);
  }
  .area {
    width: 100%;
    margin: 1rem 0;
  }
  .form-fields textarea::placeholder {
    color: #afafaf;
    text-transform: uppercase;
  }
  .form-fields input.file {
    background: transparent;
    height: 100%;
    font-size: clamp(0.888rem, 4vw, 1rem);
    width: 100%;
  }
  .form-fields input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafaf;
    text-transform: uppercase;
  }

  .form-fields input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #afafaf;
    text-transform: uppercase;
  }

  .form-fields input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #afafaf;
    text-transform: uppercase;
  }
  .specs {
    width: 100%;
  }
  .thumbnail,
  .video {
    width: 100%;
    background: #f6f6f6;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding-bottom: 1rem;
    margin: 2rem 0;
    -webkit-box-shadow: 0px 2px 26px 10px rgba(166, 166, 166, 0.06);
    box-shadow: 0px 2px 26px 10px rgba(166, 166, 166, 0.06);
  }
  .img-preview img {
    width: 100%;
  }
  .img-specs p,
  .video-specs p {
    margin: 0.2rem;
  }
  #myProgress {
    width: 100%;
    background-color: #2175c9;
    transition: 2s ease-in-out;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin: 2rem 0 1rem 0;
    -webkit-box-shadow: 0px 2px 35px 13px rgba(166, 166, 166, 0.17);
    box-shadow: 0px 2px 35px 13px rgba(166, 166, 166, 0.17);
    :hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
      cursor: pointer;
    }
  }
  #myBar {
    width: 0%;
    height: 3rem;
    background-color: #2175c9;
    transition: 1.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-family: source-sans-pro, sans-serif;
    font-size: clamp(0.888rem, 4vw, 1rem);
    color: #fff;
    border-radius: 0;
    span {
      font-size: clamp(0.888rem, 4vw, 1rem);
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
    }
  }
  .video-player {
    height: fit-content;
  }
  video {
    border-radius: 8px;
  }
  .choose-file {
    width: 100%;
    background-color: #2175c9;
    position: relative;
    color: #fff;
    border-radius: 7px;
    text-align: center;
    float: left;
    cursor: pointer;
    height: 3rem;
    content: 'test';
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 1rem 0;
    :hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
  .video-specs,
  .thumb-specs {
    width: 95%;
    margin: 0 auto;
  }
  .file {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 24px;
    width: 100%;
  }
  @media (max-width: 892px) {
    width: 95%;
    .form-fields input,
    .form-fields textarea {
      width: 94.2%;
    }
  }
`;

const TopHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justifiy-content: center;
  flex-direction: column;
  margin-top: 4rem;
  .channel-logo {
    width: 40%;
    margin: 0 auto;
  }
  @media (max-width: 892px) {
    .channel-logo {
      width: 65%;
    }
  }
`;

const CurrentUpload = styled.div`
  width: 100%;
  .below {
    display: flex;
  }
  .preview-thumb {
    width: 60%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .title-specs {
    width: 40%;
  }
  .title-specs span {
    color: #2177be;
    font-weight: 600;
  }
  .title-specs p {
    margin: 0.1rem;
  }
  @media (max-width: 892px) {
    .below {
      flex-direction: column;
    }
    .preview-thumb {
      width: 100%;
    }
    .title-specs {
      width: 100%;
    }
  }
`;

const s3Url =
  'http://clayton-fob-interface.s3-website-us-east-1.amazonaws.com/';
const api =
  'https://0l43dide4c.execute-api.us-east-1.amazonaws.com/clayton-fob-api-prod';
const cloudFront = 'https://d1gndazskr57wd.cloudfront.net/';

const creds = {
  accessKeyId: 'AKIAXF7ZD5CK2UMP3X6P',
  secretAccessKey: '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',
};

const credsTwo = {
  accessKeyId: 'AKIAXF7ZD5CK2UMP3X6P',
  secretAccessKey: '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',
};

const shortDescPlaceholder = 'Clayton FOB Roku Admin Portal';
const longDescPlaceholder = 'Clayton Family of Brands Roku Admin Portal';

const Home = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsSelected] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const [format, setFormat] = useState();
  const [modDate, setModDate] = useState();
  const [tag, setTag] = useState();

  const [vid, setVidUpload] = useState();

  const width = useWidth();

  const changeHandler = (event) => {
    console.log('file: ' + event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const changeFormat = (event) => {
    setFormat(event.target.value);
  };

  const changeModDate = (event) => {
    setModDate(event.target.value.toLocaleDateString());
    console.log(event.target.value.toLocaleDateString());
  };

  const changeTag = (event) => {
    setTag(event.target.value);
  };

  function reloadPage() {
    setTimeout(function () {
      document.cookie = 's_sess =; Max-Age=0';
      window.localStorage.clear();
      window.localStorage.removeItem('iosPwaPrompt');
      window.localStorage.removeItem('cipher');
      window.localStorage.removeItem('theme');
      window.location.reload();
    }, 3000);
  }

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append('Files', selectedFile);

    //// Upload Video

    const target = {
      Bucket: 'clayton-fob-interface',
      Key: 'claytonretail.mp4',
      Body: selectedFile ? selectedFile : null,
    };

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'us-east-1', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      var progressBar = document.getElementById('myBar');
      progressBar.innerText = '0%';
      progressBar.style.color = '#2175c9';
      progressBar.style.paddingLeft = '1rem';

      var bar = document.getElementById('myProgress');
      bar.style.opacity = '1';
      bar.style.backgroundColor = 'transparent';
      bar.style.border = '3px solid #2175c9';
      bar.style.alignItems = 'flex-start';
      bar.style.justifyContent = 'flex-start';
      progressBar.style.backgroundColor = '#fff';

      parallelUploads3.on('httpUploadProgress', (progress) => {
        // console.log("progress", progress);
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        // console.log("progress",progressPercentage);
        progressBar.style.width = progressPercentage + '%';
        progressBar.style.color = '#ffffff';
        progressBar.style.paddingLeft = '0';
        progressBar.style.backgroundColor = '#2175c9';
        if (progressPercentage < 100) {
          selectedFile.progressStatus = progressPercentage;
          progressBar.innerText = progressPercentage + '%';
          progressBar.opacity = '0.5';
        } else if (progressPercentage == 100) {
          selectedFile.progressStatus = progressPercentage;
          selectedFile.status = 'Uploaded';
          progressBar.innerHTML = '✔';
          progressBar.style.backgroundColor = '#a0cf67';
          bar.style.border = '2px solid #a0cf67';
          reloadPage();
        }
      });

      parallelUploads3.done();
    } catch (e) {
      console.log('video error: ' + e);
    }
  };

  useEffect(() => {
    fetch(api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
        console.log(api);
      })
      .catch((error) => console.log('error', error));
  }, []);

  return (
    <Protect sha512='20B99AAC0EA388366CCACB8A0EBC24D6275FCF6116C9EEA52DD3094AC7138554377BAA0F11B5044914BD6E8C2DFD78F61C3564950B3B42E94537B16D8943052A'>
      <UploadContainer style={{ backgroundImage: `url(${topBgFull})` }}>
        <Uploader>
          <div>
            {!isLoading && (
              <>
                <TopHeader>
                  <img src={ChannelLogo} className='channel-logo' alt='' />

                  <h3>{data.providerName}</h3>
                </TopHeader>

                <CurrentUpload>
                  <div class='current-specs'>
                    <ReactPlayer
                      width='100%'
                      height='fit-content'
                      url={'https://clayton-fob-interface.s3.amazonaws.com/claytonretail.mp4'}
                      controls
                      className='video-player'
                      playsinline
                    />
                  </div>
                </CurrentUpload>
              </>
            )}

            <h2>Replace Video</h2>

            <p>
              Upload a new video to replace the existing video on the Roku
              channel
            </p>

            <div className='form-fields'>
              <div className='specs'>
                <div className='video'>
                  <div className='choose-file'>
                    Choose Video
                    <input
                      type='file'
                      name='video'
                      placeholder='video'
                      className='file'
                      required
                      onChange={changeHandler}
                    />
                  </div>

                  <div className='video-specs'>
                    {selectedFile ? (
                      <div>
                        <p>
                          <strong>Filename: {selectedFile.name}</strong>
                        </p>
                        <p>Format: {selectedFile.type}</p>
                        <p>Size: {selectedFile.size} Bytes</p>
                      </div>
                    ) : (
                      <>
                        <p>
                          Video dimensions should be an aspect ratio of 16:9
                          (1280 x 720). The original/licensed content to be
                          included in your channel must be in the mp4 file
                          format
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button type='submit' id='myProgress' onClick={handleSubmission}>
                <div id='myBar'>
                  <span>Submit</span>
                </div>
              </button>
            </div>
          </div>
        </Uploader>
      </UploadContainer>
    </Protect>
  );
};

export default Home;
